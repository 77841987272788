import React, { useCallback, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import classnames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useDropzone } from 'react-dropzone'

import Loader from 'components/Loader'
import getCdnUrl from 'utils/getCdnUrl'

import 'firebase/storage'

const getImageSize = async (url) => {
  const res = await fetch(url)
  const blob = await res.blob()
  const image = new Image()
  image.src = URL.createObjectURL(blob)
  await image.decode()
  return {
    height: image.height,
    width: image.width,
  }
}

export default function ImageDropzone({ value, onChange, withSizes }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const onDrop = useCallback(async (files) => {
    setIsLoading(true)

    try {
      const file = files?.[0]

      const response = await fetch(`/api/files/generate-presigned-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: file.name,
          fileType: file.type,
        }),
      })

      const { url, key } = await response.json()

      const uploadResponse = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      })

      if (uploadResponse.ok) {
        if (withSizes) {
          try {
            const { height, width } = await getImageSize(key)
            onChange({
              height,
              width,
              url: key,
            })
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to get image size', error)

            onChange(key)
          }
        } else {
          onChange(key)
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Upload failed.')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleDelete = (event) => {
    event.stopPropagation()
    onChange(null)
  }

  const className = classnames(
    'relative flex items-center justify-center w-full rounded-md h-full border p-4 transition duration-200 font-default',
    isDragActive ? 'border-blue-600' : 'border-gray-300',
    value ? '' : 'cursor-pointer',
  )

  return (
    <>
      <div className={className} {...getRootProps()}>
        <input {...getInputProps()} />
        {isLoading ? (
          <>
            <div className="text-blue-600">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {value && (
              <>
                <div
                  style={{
                    backgroundImage: `url("${getCdnUrl(value)}")`,
                  }}
                  className="h-full w-full bg-contain bg-center bg-no-repeat"
                />
                <div className="absolute right-2 top-2 flex flex-col">
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="mb-2 rounded-full border border-gray-500 bg-white p-2 text-gray-500 hover:text-gray-700"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
              </>
            )}
            {!value && (
              <div>
                {isDragActive ? (
                  <p className="text-center text-sm text-gray-500">
                    {t('imageUpload.drop')}
                  </p>
                ) : (
                  <>
                    <p className="text-center text-sm text-gray-500">
                      {t('imageUpload.drag')}
                    </p>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
